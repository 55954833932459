.QuestionRating{
    .QuestionRating__btn{
        width: 30px;
        height: 30px;
        padding: 0;
        line-height: 1;
        margin-right: .2rem;
        @include media-breakpoint-up(lg){
            margin-right: 1.2rem;
        }
    }
}