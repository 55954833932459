.navbar {
  position: relative;
}

.logout-position {
  position: absolute;
  right: 10px;
  top: 10px;

  .btn {
    color: #ccc;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.3);
  }
}

.check {
  background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4Ij4KPGc+Cgk8Zz4KCQk8cGF0aCBkPSJNNTA0LjUwMiw3NS40OTZjLTkuOTk3LTkuOTk4LTI2LjIwNS05Ljk5OC0zNi4yMDQsMEwxNjEuNTk0LDM4Mi4yMDNMNDMuNzAyLDI2NC4zMTFjLTkuOTk3LTkuOTk4LTI2LjIwNS05Ljk5Ny0zNi4yMDQsMCAgICBjLTkuOTk4LDkuOTk3LTkuOTk4LDI2LjIwNSwwLDM2LjIwM2wxMzUuOTk0LDEzNS45OTJjOS45OTQsOS45OTcsMjYuMjE0LDkuOTksMzYuMjA0LDBMNTA0LjUwMiwxMTEuNyAgICBDNTE0LjUsMTAxLjcwMyw1MTQuNDk5LDg1LjQ5NCw1MDQuNTAyLDc1LjQ5NnoiIGZpbGw9IiNGRkZGRkYiLz4KCTwvZz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K');
}

footer {
  border: 1px solid #e3e2e2;
  small {
    line-height: 1.4;
    display: inline-block;
  }
}

.Scene {
  padding-top: 60px;
  &.Scene--logged {
    padding-top: 130px;
    @media (min-width: 768px) {
      padding-top: 180px;
    }
    @media (min-width: 992px) {
      padding-top: 120px;
    }
  }
  .Scene__header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 199;
    background-color: #0847a6;

    .Header__logo {
      img {
        height: 25px;
      }
    }

    @media (min-width: 992px) {
      position: fixed;
    }
  }
}

.CounterStep {
  display: block;
  user-select: none;

  .CounterStep__nav {
    width: 100%;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;

    @media (min-width: 992px) {
      width: auto;
      display: block;
    }
  }

  .CounterStep__item {
    display: inline-block;
    margin: 2px 5px 13px;
    color: #bbb;
    border-radius: 2rem;
    line-height: 1.7;
    border: 1px dashed #eee;
    padding: 0;
    width: 2.5rem;
    height: 2.5rem;
    vertical-align: middle;
    position: relative;
    flex: 1;
    transition: all 0.3s ease;

    @media (min-width: 768px) {
      padding: 0.2rem 0.5rem;
      line-height: 1;
    }

    @media (min-width: 992px) {
      width: auto;
      margin-bottom: 8px;
    }

    .CounterStep__item__label {
      position: absolute;
      top: auto;
      bottom: -15px;
      width: 100%;
      left: 0;
      text-align: center;
      font-size: 0.5rem;

      small {
        position: absolute;
        bottom: -23px;
        left: -10px;
        font-size: 0.6rem;
        right: -10px;
        background: $yellow;
        border-radius: 10px;
        padding: 2px 3px;
        color: #000;
        line-height: 1;
        &::before {
          @include triangle($yellow, up, 6px);
          bottom: 100%;
          left: 50%;
          margin-left: -3px;
        }
      }

      @media (min-width: 768px) {
        position: relative;
        bottom: auto;
        font-size: 0.7rem;
      }
      @media (min-width: 992px) {
        font-size: 0.9rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }

    .CounterStep__item__number {
      line-height: 2.1;
      display: inline-block;
      font-size: 1rem;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      text-align: center;
      vertical-align: middle;
      transition: all 0.3s ease;

      @media (min-width: 576px) {
        width: 2rem;
        line-height: 1.8;
      }
    }

    &.is-complete,
    &.is-complete.is-current {
      background-color: $success;
      border-style: solid;
      border-color: $success;
      color: $success;

      .CounterStep__item__number {
        background-size: 30%;
        background-repeat: no-repeat;
        background-position: 50%;
        @extend .check;
        text-indent: 900px;
        overflow: hidden;
        position: relative;
      }

      @media (min-width: 768px) {
        &,
        .CounterStep__item__label {
          color: #fff;
        }
        .CounterStep__item__number {
          top: auto;
          background-size: 40%;
        }
      }
    }

    &.is-current {
      color: #000;
      border-style: solid;
      background: #eee;

      .CounterStep__item__label {
        color: #000;
      }
    }
  }
}

.Header {
  .Header__logo {
    img {
      transition: all 0.3s ease;
    }
  }
  .Header__user {
    .Header__user__name {
      transition: all 0.3s ease;
      color: $white;
    }

    .Header__user__help {
      transition: all 0.3s ease;
      height: 45px;
      overflow: hidden;
      @media (min-width: 768px) {
        height: 25px;
      }
    }
  }
}

.is-scroll {
  @media (min-width: 768px) {
    .Header {
      .Header__logo {
        img {
          transition: all 0.3s ease;
          height: 30px;
        }
      }
      .Header__user {
        .Header__name {
          transition: all 0.3s ease;
          font-size: 0.9rem;
        }
        .Header__help {
          transition: all 0.3s ease;
          height: 0;
        }
      }
    }
  }
  .CounterStep {
    @media (min-width: 768px) {
      .CounterStep__item {
        transition: all 0.3s ease;
        height: 1.6rem;

        .CounterStep__item__number {
          transition: all 0.3s ease;
          line-height: 0.7;
        }

        .CounterStep__item__label {
          top: -0.1rem;
        }

        .CounterStep__item__number {
          top: -2px;
        }
      }
    }
  }
}
