.NotifierButton {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: transparent;
  width: 100%;
  font-size: 12px;
  border: 0;
  text-align: right;
  text-decoration: none;

  span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    i {
      margin-right: 5px;
    }
  }

  .notify {
    font-size: 1.1em;
    font-weight: 700;
    color: #666;
    &:hover {
      color: $danger;
    }
  }

  .error {
    color: $danger;
  }

  .success {
    color: $green;
  }
}
