.QuestionStar{
    &:hover .QuestionStar__item .QuestionStar__btn{
        color: $yellow;
        .unchecked{
            display: none;
        }
        .checked{
            display: inline;
        }
    }
    .QuestionStar__item{
        .QuestionStar__btn{
            color: #333;

            &,
            &:hover{
                text-decoration: none;
            }

            .checked{
                display: none;
            }
        }

        &:hover ~ .QuestionStar__item .QuestionStar__btn {
            color: #333;
            .checked{
                display: none;
            }
            .unchecked{
                display: inline;
            }
        }

        &.is-checked{
            .QuestionStar__btn{
                color: $yellow;
                .unchecked{
                    display: none;
                }
                .checked{
                    display: inline;
                }
            }
        }

        &.is-checked,
        & > .QuestionStar__btn:hover{
            
        }
    }
}