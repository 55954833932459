.Panel {
  padding-bottom: 40px;

  .Panel__btn-send {
  }
}

.PanelEnviar {
  text-align: center;
}

.PanelStart {
  text-align: center;

  .PanelStart__title {
    font-size: 1.3rem;
    margin-bottom: 30px;

    @media (min-width: 576px) {
      font-size: 1.6rem;
      margin-bottom: 50px;
    }
  }

  .PanelStart__list {
    @media (min-width: 576px) {
      display: flex;
      flex-direction: column;
    }
    @media (min-width: 768px) {
      flex-direction: row;
    }

    .PanelStart__list__item {
      padding-right: 2rem;
      padding-left: 2rem;
      margin-bottom: 2rem;

      @media (min-width: 768px) {
        padding-right: 1rem;
        padding-left: 1rem;
        flex: 1;
      }

      .PanelStart__list__item__icon {
        border-radius: 50%;
        overflow: hidden;
        height: 8rem;
        width: 8rem;
        margin: 0 auto 20px;
        background-color: transparent;
        border: 1px solid #333;
        padding: 2rem;
        vertical-align: middle;
        display: flex;
        align-content: center;
        align-items: center;

        @media (min-width: 576px) {
          height: 12rem;
          width: 12rem;
          padding: 3rem;
        }

        img {
          display: block;
          max-width: 100%;
        }
      }

      &.is-complete {
        .PanelStart__list__item__icon {
          background: #04843e;
          border-color: transparent;
        }
      }

      .PanelStart__list__item__label {
      }
    }
  }
  .PanelStart__btn-avancar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    margin: auto;
    line-height: 1;
    padding: 13px;
    text-align: left;
    svg {
      margin-left: 15px;
    }
  }
}

.PanelSocio__vazio {
  background: #ebebeb;
  text-align: center;
}
