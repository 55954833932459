@import './scss/variables';

@import '~bootstrap/scss/bootstrap';
@import './scss/mixins';

$image-path: './images';

body {
  background-color: #fff;
}

dt,
dd {
  line-height: 1;
}

dt {
  margin-bottom: 0.7rem;
}

.cursor-pointer {
  cursor: pointer;
}

::placeholder,
input::placeholder,
/* Chrome/Opera/Safari */
::-webkit-input-placeholder,
/* Firefox 19+ */
::-moz-placeholder,
/* IE 10+ */
:-ms-input-placeholder,
/* Firefox 18- */
:-moz-placeholder {
  color: #f7f7f7 !important;
}

.PanelToggler {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-color: #f7f7f7;

  .is-closed {
    text-decoration: none;

    &:hover {
      background: #f7f7f7;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
  }
}

.AreaDownload {
  position: relative;

  .AreaDownload__codigo {
    position: absolute;
    font-weight: 700;
    color: #000;
    font-size: 50px;
    left: 76px;
    top: 66px;
  }

  .AreaDownload__nome {
    position: absolute;
    font-weight: 700;
    font-size: 57px;
    left: 60px;
    top: 243px;
    max-width: 1119px;
    text-indent: 137px;
    line-height: 75px;
    height: 166px;
    overflow: hidden;
  }

  .AreaDownload__data {
    position: absolute;
    right: 49px;
    bottom: 397px;
    height: 80px;
    font-size: 45px;
    width: 650px;
    font-weight: 700;

    div {
      position: absolute;
      text-align: center;

      &.dia {
        left: 70px;
      }
      &.mes {
        left: 220px;
      }
      &.ano {
        left: 524px;
      }
    }
  }
}

.Documento {
  .card-img-top {
    border: 1px solid transparent;
    &:hover {
      border-color: $primary;
    }
  }
  .Documento__title {
    margin-bottom: 0;
  }
  .Documento__label {
    font-size: 0.9rem;
  }
}

.hidden-frame {
  width: 0;
  height: 0;
  overflow: hidden;

  .overlay {
    position: relative;
    display: inline-block;
    overflow: hidden;

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background: url($image-path+'/mascara-documentos-pdf.png');
    }
  }
}

@keyframes loading {
  to {
    background-position: 350% 0;
  }
}

.loading {
  position: relative;
  &:empty::after {
    display: block;
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #ebebeb;
    background-image: linear-gradient(
      90deg,
      rgba(211, 211, 211, 0) 0,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(211, 211, 211, 0) 100%
    );
    background-size: 300px;
    background-position: -150% 0;
    background-repeat: no-repeat;
    animation: loading 1.5s infinite;
  }
}

.Cliente__nome {
  min-height: 30px;
  @extend .loading;
  &:empty::after {
    max-width: 250px;
  }
}
.PanelToggler__title {
  min-height: 25px;
  max-width: 140px;
  margin-bottom: 5px;
  @extend .loading;
}
.PanelToggler__subtitle {
  min-height: 14px;
  min-width: 200px;
  display: inline-block;
  @extend .loading;
}

@import './components/Scene/Scene';
@import './components/Cliente/Cliente';
@import './components/Panel/PanelPesquisa/QuestionStar';
@import './components/Panel/PanelPesquisa/QuestionRating';
@import './components/Panel/Documento/NotifierButton/styles';
@import './components/Panel/DocumentoUpload/CamposDocumentos';
//Selector
.AsyncSelect-container {
  &.is-invalid {
    .AsyncSelect__control {
      border-color: #dc3545;
    }
    & ~ .invalid-feedback {
      display: block;
    }
  }

  .AsyncSelect__control {
    border-width: 2px;

    &--is-focused {
      border-width: 2px;

      .AsyncSelect__single-value {
        color: #666;
      }
    }
  }
}
